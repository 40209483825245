import styled from '@emotion/styled'
import Image from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export const Section = styled.div`
  min-height: 100vh;
  font-family: ${ props => props.theme.fonts.body };
  margin-bottom: 4em;
  margin: auto;
  padding: 2% 28px;
  
  @media only screen and (min-width: 768px) {
    padding: 2% 10% !important;
  }
  
  .tag-pill {
    background-color: ${ props => props.theme.colors.accent };
    color: #333;
    border-radius: 5em;
    display: inline-block;
    font-size: 13px;
    padding: .5em 1em .35em;
    margin: 0 1em 2em 0;
  
    @media only screen and (min-width: 768px) {
      margin: 0 1em 1em 0;
      font-size: 15px;
    }
  }
  
  .project-date {
    display: inline-block;
    float: none;
  
    @media only screen and (min-width: 768px) {
      float: right;
      border: 1px solid ${ props => props.theme.colors.body };
      padding: .5em 1em .35em;
    }
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  
    .col {
      width: 100%;
  
      @media only screen and (min-width: 768px) {
        width: 50%;
      }
  
      &.tags-wrapper {
        float: left;
        text-align: left;
        order: 1;
  
        @media only screen and (min-width: 768px) {
          order: 2;
          float: right;
          text-align: right;
        }
      }
  
      &.description-wrapper {
        order: 2;
  
        @media only screen and (min-width: 768px) {
          order: 1;
        }
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 25px;
  font-family: ${ props => props.theme.fonts.body };
  padding: 1em 0 .5em;
`;

export const Subtitle = styled.h4`
  font-family: ${ props => props.theme.fonts.body };
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 1.5em;
  max-width: 500px;
`;

export const Small = styled.small`
  display: block;
  max-width: 700px;
  margin-bottom: 2.5em;
`;

export const Description = styled.p`
  max-width: 700px;
  margin-bottom: 2em;
`;

export const Col = styled.div`
  align-self: center;
`;

export const HeaderImage = styled(Image)`
  margin-top: 2em;
  margin-bottom: 3em;
`;

export const StyledAniLink = styled(AniLink)`
  color: ${ props => props.theme.colors.text };
  
  &:hover {
    color: ${ props => props.theme.colors.text };
    opacity: .75;
  }
`;
