import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Col, Description, HeaderImage, Section, Small, StyledAniLink, Subtitle, Title } from './Project.styles'
import { Button } from '../components/Button.styles'

const ProjectTemplate = (props) => {
  const {
    title,
    subtitle,
    description,
    stack,
    cta,
    thumbnail,
    externalUrl,
    tags,
    date
  } = props.data.project;

  return (
      <Layout>
        <div className="project">
          <SEO title={ title } />

          <Section className="intro">
            <StyledAniLink fade to="/">
              &larr; Back to projects
            </StyledAniLink>
            <div className="row">
              <Col className="col">
                <Title>
                  { title }
                </Title>
              </Col>
              <Col className="col">
                <small className="project-date">
                  { date }
                </small>
              </Col>
            </div>

            <HeaderImage
                fluid={ thumbnail.localFile.childImageSharp.fluid }
                alt={ title }
            />

            <div className="row">
              <div className="col description-wrapper">
                <Subtitle>
                  { subtitle }
                </Subtitle>
                <Description>
                  { description.description }
                </Description>
                <Small>
                  {
                    stack
                        ? <>Stack: { stack.stack }</>
                        : ''
                  }
                </Small>
                <a href={ externalUrl }
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <Button>
                    { cta }
                  </Button>
                </a>
              </div>
              <div className="col tags-wrapper">
                {
                  tags.map((tag, index) => (
                      <span
                          key={ index }
                          className="tag-pill"
                      >
                      { tag }
                    </span>
                  ))
                }
              </div>
            </div>
          </Section>
        </div>
      </Layout>
  );
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectQuery($slug: String!) {
    project: contentfulProject(slug: { eq: $slug }) {
      cta
      date
      description {
        description
      }
      externalUrl
      id
      slug
      subtitle
      stack {
        stack
      }
      tags
      title
      thumbnail {
        localFile {
          childImageSharp {
            fluid {
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
              aspectRatio
              base64
            }
          }
        }
      }
    }
  }
`;
